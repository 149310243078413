import { useEffect } from "react";

// react-redux
import { useSelector, useDispatch } from "react-redux";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Loader from "components/Loader";

// Soft UI Dashboard React examples
import TimelineItem from "examples/Timeline/TimelineItem";

// Translate
import { useTranslation } from "react-i18next";

// Utils
import { timestampToDateTime } from "utils/timestampToDateTime";
import { STATUS } from "constants/fetchStatus";
import { getMessages } from "redux/actions/messages";

export default function AdminMessages() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data: messages, status: messagesStatus } = useSelector(
    (state) => state.messages
  );

  useEffect(() => {
    dispatch(getMessages());
  }, []);

  const initialMessage = (
    <TimelineItem
      color="success"
      icon="notifications"
      title={"Messages will show up here"}
      dateTime={`2023-10-17 by Admin`}
    />
  );

  return (
    <Card style={{ overflowY: "auto", height: "17.5rem" }}>
      <SoftBox pt={2} px={3}>
        <SoftTypography mt={3} mr={3} variant="section" className="title">
          {t("Admin messages")}
        </SoftTypography>
      </SoftBox>
      <SoftBox px={2} pt={1} style={{ overflowY: "scroll" }}>
        {messages.length === 0 && messagesStatus === STATUS.OK
          ? initialMessage
          : ""}
        {messagesStatus === STATUS.LOADING && <Loader />}
        {messagesStatus === STATUS.ERROR && t("Oops, something went wrong...")}
        {messages.map(({ id, content, author, create_date }) => {
          return (
            <TimelineItem
              key={id}
              color="success"
              icon="notifications"
              title={content}
              dateTime={`${timestampToDateTime(create_date)} by ${author}`}
            />
          );
        })}
      </SoftBox>
    </Card>
  );
}
