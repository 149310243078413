import { fetchExchangePair } from "api/pairs"
import { BASE_COIN } from "constants"
import { STATUS } from "constants/fetchStatus"
import useFetch from "hooks/useFetch"
import { useSelector } from "react-redux"

export default function usePairData(pair, exchange) {

    const firstSymbolName = pair.split("-")[0]
    const secondSymbolName = pair.split("-")[1]
    const firstSymbolPairWithBaseCoin = `${firstSymbolName}-${BASE_COIN}`
    const secondSymbolPairWithBaseCoin = `${secondSymbolName}-${BASE_COIN}`

    const walletSymbols = useSelector(state => state.wallets[exchange].pairs)
    const { data: pairData, status: pairStatus } = useFetch(fetchExchangePair, exchange, pair)
    const { data: firstSymbolData, status: firstSymbolStatus } = useFetch(fetchExchangePair, exchange, firstSymbolPairWithBaseCoin)
    const { data: secondSymbolData, status: secondSymbolStatus } = useFetch(fetchExchangePair, exchange, secondSymbolPairWithBaseCoin)

    const firstSymbolFromWallet = walletSymbols.find(symbol => symbol.symbol === firstSymbolName && symbol.walletType === "spot")
    const secondSymbolFromWallet = walletSymbols.find(symbol => symbol.symbol === secondSymbolName && symbol.walletType === "spot")
    const getStatus = () => {
        if (pairStatus !== STATUS.OK || firstSymbolStatus !== STATUS.OK || secondSymbolStatus !== STATUS.OK) {
            return STATUS.LOADING
        } else return STATUS.OK
    }

    return {
        firstSymbol: {
            name: firstSymbolName,
            free: firstSymbolFromWallet?.free || 0,
            price: firstSymbolData?.price || 0
        },
        secondSymbol: {
            name: secondSymbolName,
            free: secondSymbolFromWallet?.free || 0,
            price: secondSymbolData?.price || 0
        },
        ...pairData,
        status: getStatus()
    }
}