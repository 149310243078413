import SoftBox from 'components/SoftBox'
import useAssets from 'hooks/useAssets'

function IllustrationImage() {
    const { illustration } = useAssets()

    return (
        <SoftBox
            component="img"
            src={illustration.image}
            alt="branding-illustration"
            width="100%"
            sx={illustration.styles}
            borderRadius="lg"
        />
    )
}

export default IllustrationImage

