export const en = {
  translation: {
    // Generals
    Activate: "Activate",
    "You need to activate an exchange": "You need to activate an exchange",
    "entries per page": "entries per page",
    "Search...": "Search...",
    actions: "actions",
    pair: "pair",
    "change 24hs": "change 24hs",
    price: "price",
    "volume 24hs": "volume 24hs",
    symbol: "symbol",
    side: "side",
    amount: "amount",
    cost: "cost",
    type: "type",
    date: "date",
    Showing: "Showing",
    to: "to",
    of: "of",
    entries: "entries",
    Distribution: "Distribution",
    "Fiat value": "Fiat value",
    Free: "Free",
    Locked: "Locked",
    profit: "profit",
    "%profit": "%Profit",
    cancel: "cancel",
    save: "save",
    "Select...": "Select...",
    "change data!": "change data!",
    "content manager": "content manager",
    "Coming soon!": "Coming soon!",
    all: "all",
    "Trade closed": "Trade closed",
    Notifications: "Notifications",

    //SideNavbar
    Home: "Home",
    Trade: "Trade",
    Orders: "Orders",
    Wallet: "Wallet",
    Arbitration: "Arbitration",
    Markets: "Markets",
    News: "News",
    Admin: "Admin",
    Profile: "Profile",
    "upgrade to premium": "upgrade to premium",

    //DashboardNavbar
    balance: "balance",

    // Home
    "Trades (open)": "Trades (open)",
    "Trades (closed)": "Trades (closed)",
    Arbitrations: "Arbitrations",
    Portfolio: "Portfolio",
    "Admin messages": "Admin messages",
    "Oops, something went wrong...": "Oops, something went wrong...",
    "Last arbitrage opportunity found": "Last arbitrage opportunity found",
    "Read More": "Read More",

    // Trade
    Buy: "Buy",
    Sell: "Sell",
    Market: "Market",
    Limit: "Limit",
    Amount: "Amount",
    Available: "Available",
    Total: "Total",
    Price: "Price",
    "buy market": "buy market",
    "sell market": "sell market",
    "buy limit": "buy limit",
    "sell limit": "sell limit",
    favorites: "favorites",

    // Orders
    "No trades yet!": "No trades yet!",
    "No opportunities yet!": "No opportunities yet!",
    Closed: "Closed",
    Opened: "Opened",
    Arbitrage: "Arbitrage",

    // Wallet
    "Something failed with this wallet, try refreshing the wallets and verify your keys.":
      "Something failed with this wallet, try refreshing the wallets and verify your keys.",
    futures: "futures",

    // Arbitration
    "No strategies yet!": "No strategies yet!",
    "All Strategies": "All Strategies",
    "new strategy": "new strategy",
    "Strategy Information": "Strategy Information",
    Opportunities: "Opportunities",
    Strategies: "Strategies",
    Actions: "Actions",
    "If an opportunity is found and it meets one of your strategies criteria, 2 orders will be created":
      "If an opportunity is found and it meets one of your strategies criteria, 2 orders will be created",
    "These are the last price differences found. Create strategies to profit from the next ones!":
      "These are the last price differences found. Create strategies to profit from the next ones!",
    "These are your triggered strategies":
      "These are your triggered strategies",
    "Trades triggered": "Trades triggered",
    Pair: "Pair",
    Side: "Side",
    Exchange: "Exchange",
    Date: "Date",
    "Exchange 1": "Exchange 1",
    "Exchange 2": "Exchange 2",
    Profit: "Profit",
    Gained: "Gained",
    "Required balances": "Required balances",
    "Ok!": "Ok!",
    "You need the premium version to add more!":
      "You need the premium version to add more!",
    "You have reached your limit": "You have reached your limit",
    "Please enter a percentage of at least 0.5% to ensure fees do not impact profitability.": "Please enter a percentage of at least 0.5% to ensure fees do not impact profitability.",

    // Top Coins
    "Today's top cryptocurrencies by market capitalization":
      "Today's top cryptocurrencies by market capitalization",
    Source: "Source",
    "USD Price": "USD Price",
    "24H Volume": "24H Volume",
    "Market Cap": "Market Cap",
    heatmap: "heatmap",

    // Profile
    settings: "settings",
    profile: "profile",
    exchanges: "exchanges",
    plans: "plans",
    "sign out": "sign out",
    Settings: "Settings",
    security: "security",
    Platform: "Platform",
    "Remove small balances": "Remove small balances",
    "Base token": "Base token",
    "Trade opened": "Trade opened",
    "Arbitrage opportunity found": "Arbitrage opportunity found",
    "profile information": "profile information",
    "edit profile": "edit profile",
    username: "User Name",
    "full name": "Full Name",
    email: "Email",
    country: "Country",
    phone: "Phone",
    Enabled: "Enabled",
    Disabled: "Disabled",
    "Public Key": "Public Key",
    "Private Key": "Private Key",
    Password: "Password",
    Open: "Open",
    "Save API Keys": "Save API Keys",
    "You need to add keys": "You need to add keys",
    "basic information": "basic information",
    "change password": "change password",
    "Current Password": "Current Password",
    "current password": "current password",
    "New Password": "New Password",
    "new password": "new password",
    "Confirm New Password": "Confirm New Password",
    "confirm new password": "confirm new password",
    "Password requirements": "Password requirements",
    "Please follow this guide for a strong password":
      "Please follow this guide for a strong password",
    "One capital letter": "One capital letter",
    "Min 8 characters": "Min 8 characters",
    "One number (2 are recommended)": "One number (2 are recommended)",
    "Change it often": "Change it often",
    change: "change",
    "My referrals": "My referrals",
    "These are the referrals that are currently making you money":
      "These are the referrals that are currently making you money",
    earnings: "earnings",
    "avg. user value": "avg. user value",
    "How to use": "How to use",
    "Integrate your referral code in 3 easy steps":
      "Integrate your referral code in 3 easy steps",
    "1. Share your code for people to register":
      "1. Share your code for people to register",
    "2. Get paid monthly for each referral":
      "2. Get paid monthly for each referral",
    "3. Second and third level referrals are also rewarded!":
      "3. Second and third level referrals are also rewarded!",
    Rewards: "Rewards",
    "This is how much you will get paid": "This is how much you will get paid",
    "usdc / MO": "usdc / MO",
    "Level 1": "Level 1",
    "Level 2": "Level 2",
    "Level 3": "Level 3",
    monthly: "monthly",
    "Referral code": "Referral code",
    "Make sure people register with the following code":
      "Make sure people register with the following code",
    copy: "copy",
    "paid users": "paid users",
    referrals: "referrals",
    user: "user",
    "contract count": "contract count",
    lvl: "lvl",
    "Required fields": "Required fields",
    "Password must contain at least 8 characters, 1 uppercase, and 1 number.":
      "Password must contain at least 8 characters, 1 uppercase, and 1 number.",
    "Passwords do not match. Please try again.":
      "Passwords do not match. Please try again.",
    "Invalid current password": "Invalid current password",

    // Lock
    "Your session has expired, please enter your account password.":
      "Your session has expired, please enter your account password.",
    unlock: "unlock",
    "Is this your account?": "Is this your account?",
    "Sign in": "Sign in",

    // Admin
    users: "users",
    messages: "messages",
    videos: "videos",
    status: "status",
    "register date": "register date",
    action: "action",
    author: "author",
    content: "content",
    "create date": "create date",
    title: "title",
    url: "url",
    "Editing user: ": "Editing user: ",
    remove: "remove",
    "Personal information": "Personal information",
    Email: "Email",
    "User Name": "User Name",
    "Full Name": "Full Name",
    Status: "Status",
    Phone: "Phone",
    Country: "Country",
    "Register Date": "Register Date",
    Registered: "Registered",
    Activated: "Activated",
    "User earnings: ": "User earnings: ",
    "Paid to user: work in progress": "Paid to user: work in progress",
    "Delete user": "Delete user",
    "You won't be able to revert this!": "You won't be able to revert this!",
    "Yes, DELETE!": "Yes, DELETE!",
    "Deleted!": "Deleted!",
    User: "User",
    "has been deleted": "has been deleted",
    Error: "Error",
    "could not be deleted": "could not be deleted",
    "+ new Message": "+ new Message",
    "Editing message:": "Editing message:",
    "Message information": "Message information",
    Author: "Author",
    Content: "Content",
    "Create Date": "Create Date",
    "New message": "New message",
    "Delete Message?": "Delete Message?",
    "Message has been deleted": "Message has been deleted",
    "Message could not be deleted": "Message could not be deleted",
    "+ new Video": "+ new Video",
    "Editing video:": "Editing video:",
    "Video information": "Video information",
    Title: "Title",
    "Youtube URL": "Youtube URL",
    "Invalid YouTube URL": "Invalid YouTube URL",
    "New video": "New video",
    "Delete Video?": "Delete Video?",
    "Video has been deleted": "Video has been deleted",
    "Video could not be deleted": "Video could not be deleted",
    Yes: "Yes",

    // Notifications
    "View all": "View all",
    "Mark as read": "Mark as read",
    "unread notifications": "unread notifications",
    Authorize: "Authorize",
    authorization: "authorization",
    trading: "trading",
    "No notifications!": "No notifications!",

    // Plans
    "Plans & pricing": "Plans & pricing",
    "Free Plan": "Free Plan",
    "Premium Plan": "Premium Plan",
    "All prices on this website are excluding VAT (if applicable)":
      "All prices on this website are excluding VAT (if applicable)",
    Monthly: "Monthly",
    free: "free",
    premium: "premium",
    corporate: "corporate",
    "Connect x2 Exchanges": "Connect x2 Exchanges",
    "Connect x9 Exchanges": "Connect x9 Exchanges",
    "30 Operations Per Month": "30 Operations Per Month",
    "Unlimited Operations": "Unlimited Operations",
    "x2 Auto Arbitrage Strategies": "x2 Auto Arbitrage Strategies",
    "x10 Auto Arbitrage Strategies": "x10 Auto Arbitrage Strategies",
    "Trading Bots": "Trading Bots",
    "Multilevel Referral Plan": "Multilevel Referral Plan",
    "Market Trends & News": "Market Trends & News",
    "Active Education": "Active Education",
    "Limit & Market Trading": "Limit & Market Trading",
    "start now": "start now",
    "Contact us": "Contact us",
    "cancel suscription": "cancel suscription",
    "Are you sure?": "Are you sure?",
    "Do you really want to unsubscribe?": "Do you really want to unsubscribe?",
    "Yes, unsubscribe!": "Yes, unsubscribe!",
    "No, keep my subscription": "No, keep my subscription",
    "Unsubscribed!": "Unsubscribed!",
    "Your subscription has been successfully canceled. We're sorry to see you go!":
      "Your subscription has been successfully canceled. We're sorry to see you go!",
    "Oops...": "Oops...",
    "Something went wrong! Please try again later.":
      "Something went wrong! Please try again later.",
    "Message sent!": "Message sent!",
    "Thank you for contacting us!": "Thank you for contacting us!",
    "We will be in touch soon.": "We will be in touch soon.",
    "Something went wrong.": "Something went wrong.",
    "Please try again.": "Please try again.",
    "By clicking Talk to CTrading, I acknowledge I have read and understand the ":
      "By clicking Talk to CTrading, I acknowledge I have read and understand the ",
    "Privacy Notice.": "Privacy Notice.",
    "Talk to CTrading": "Talk to CTrading",
    "Company email": "Company email",
    "Email address": "Email address",
    "Your name": "Your name",
    "Phone number": "Phone number",
    "Phone number (optional)": "Phone number (optional)",
    "How can we help?": "How can we help?",
    "Your company needs": "Your company needs",
    "Trading Infrastructure": "Trading Infrastructure",
    "Customized Services": "Customized Services",
    "Account Reconciliation": "Account Reconciliation",
    "Deposit and Withdrawal System": "Deposit and Withdrawal System",
    "User Management Dashboard": "User Management Dashboard",
    "Statistical Charts": "Statistical Charts",
    "White Label": "White Label",
    "Let's Talk": "Let's Talk",
    "Error redirecting to Stripe Checkout. Please try again or contact support if the issue persists.":
      "Error redirecting to Stripe Checkout. Please try again or contact support if the issue persists.",
    "Your subscription has been successfully processed. Thank you for subscribing!":
      "Your subscription has been successfully processed. Thank you for subscribing!",
    "There was an error processing your charge. Please contact support for assistance.":
      "There was an error processing your charge. Please contact support for assistance.",

    // Auth
    "Welcome back!": "Welcome back!",
    "Enter your email and password to sign in":
      "Enter your email and password to sign in",
    "sign in": "sign in",
    "sign up": "sign up",
    "Remember me": "Remember me",
    "Forgot your password?": "Forgot your password?",
    "Phone Number (optional)": "Phone Number (optional)",
    "Already have an account?": "Already have an account?",
    "Reset Password": "Reset Password",
    "You will receive an e-mail in maximum 60 seconds":
      "You will receive an e-mail in maximum 60 seconds",
    reset: "reset",
    "Enter your e-mail": "Enter your e-mail",
    "Confirmed Password": "Confirmed Password",
    "2-Step Verification": "2-Step Verification",
    "send code": "send code",
    "Is this your account? If not, please sign in":
      "Is this your account? If not, please sign in",
    "here.": "here.",
    "Not all needed params were provided":
      "Not all needed params were provided",
    "User does not exist": "User does not exist",
    "Invalid password": "Invalid password",
    "Internal server error": "Internal server error",
    "User is not active and requires confirmation from the administrator":
      "User is not active and requires confirmation from the administrator",
    "Invalid 2FA code, please try again.":
      "Invalid 2FA code, please try again.",
    "You will receive a password recovery link at your email address in a few minutes.":
      "You will receive a password recovery link at your email address in a few minutes.",
    "Your password has been reset.": "Your password has been reset.",
    "User not found": "User not found",
    "Pasted value exceeds 6 characters.": "Pasted value exceeds 6 characters.",
    "Email is required.": "Email is required.",
    "Password is required.": "Password is required.",
    "Email and Password are required.": "Email and Password are required.",
    "Input fields are required.": "Input fields are required.",
    "Fields are required.": "Fields are required.",
    "User successfully created. Log in to get a contract.":
      "User successfully created. Log in to get a contract.",
    "User successfully created. Check your email for verification link.":
      "User successfully created. Check your email for verification link.",
    "Email or Username are already taken":
      "Email or Username are already taken",
    "Unauthorized: No token provided": "Unauthorized: No token provided",
    "Your subscription has been updated. Please log in again to continue.":
      "Your subscription has been updated. Please log in again to continue.",
    "Unauthorized: Action not allowed": "Unauthorized: Action not allowed",

    // Tours
    skip: "skip",
    back: "back",
    next: "next",
    close: "close",
    "We're glad to have you here. Let's take a quick tour to get you started.": "We're glad to have you here. Let's take a quick tour to get you started.",
    "Manage your trades efficiently with our easy-to-use interface. View information about your trades and arbitrage actions. This section provides details on your trades.": "Manage your trades efficiently with our easy-to-use interface. View information about your trades and arbitrage actions. This section provides details on your trades.",
    "Keep track of your funds in the wallet section. View your balance for each currency in the wallet section. This section provides a summary of your balances.": "Keep track of your funds in the wallet section. View your balance for each currency in the wallet section. This section provides a summary of your balances.",
    "Stay updated with important notifications and messages from the admin. Check this section regularly to ensure you don't miss any critical information.": "Stay updated with important notifications and messages from the admin. Check this section regularly to ensure you don't miss any critical information.",
    "Get the latest updates and news about the market. Stay informed with real-time news that can impact your trading decisions.": "Get the latest updates and news about the market. Stay informed with real-time news that can impact your trading decisions.",
    "Explore arbitrage opportunities to maximize your profits. This section provides tools and insights to help you take advantage of price differences across markets.": "Explore arbitrage opportunities to maximize your profits. This section provides tools and insights to help you take advantage of price differences across markets.",
    "Watch informative and educational videos. Learn more about trading strategies, market analysis, and how to use our platform effectively.": "Watch informative and educational videos. Learn more about trading strategies, market analysis, and how to use our platform effectively.",
    "This is the Trader section where you can manage your trades, monitor market trends, and execute trading strategies.": "This is the Trader section where you can manage your trades, monitor market trends, and execute trading strategies.",
    "Select the exchange you want to trade on.": "Select the exchange you want to trade on.",
    "Toggle between viewing all pairs or only your favorite pairs.": "Toggle between viewing all pairs or only your favorite pairs.",
    "Click the Trade button to execute a trade for the selected pair.": "Click the Trade button to execute a trade for the selected pair.",
    "Click the star icon to add or remove a pair from your favorites.": "Click the star icon to add or remove a pair from your favorites.",
    "This is the trading section where you can execute your buy and sell orders.": "This is the trading section where you can execute your buy and sell orders.",
    "Select whether you want to Buy or Sell the chosen pair.": "Select whether you want to Buy or Sell the chosen pair.",
    "Choose the type of order you want to place: Market or Limit.": "Choose the type of order you want to place: Market or Limit.",
    "Enter the amount of the asset you want to trade.": "Enter the amount of the asset you want to trade.",
    "The total amount will be calculated based on the amount you entered and the current market price.": "The total amount will be calculated based on the amount you entered and the current market price.",
    "Click the button to execute your trade.": "Click the button to execute your trade.",
    "This section allows you to view your orders. You can filter between closed and opened orders.": "This section allows you to view your orders. You can filter between closed and opened orders.",
    "Refresh the orders list to see the latest updates.": "Refresh the orders list to see the latest updates.",
    "These are your closed orders.": "These are your closed orders.",
    "These are your opened orders.": "These are your opened orders.",
    "This section allows you to view and manage your wallet. You can see the distribution of your assets, refresh the page, and filter by different exchanges.": "This section allows you to view and manage your wallet. You can see the distribution of your assets, refresh the page, and filter by different exchanges.",
    "Click here to refresh your wallet data.": "Click here to refresh your wallet data.",
    "View your assets across all exchanges or filter by specific exchanges.": "View your assets across all exchanges or filter by specific exchanges.",
    "This section allows you to view and manage your arbitration opportunities, strategies, and actions.": "This section allows you to view and manage your arbitration opportunities, strategies, and actions.",
    "Refresh the arbitrage list to see the latest updates.": "Refresh the arbitrage list to see the latest updates.",
    "View the latest price differences found across exchanges.": "View the latest price differences found across exchanges.",
    "Create and manage your trading strategies.": "Create and manage your trading strategies.",
    "View your triggered actions.": "View your triggered actions.",
    "Click here to create a new trading strategy.": "Click here to create a new trading strategy.",
    "This section allows you to input the details for creating a new trading strategy.": "This section allows you to input the details for creating a new trading strategy.",
    "Select the first exchange where the trading strategy will be executed.": "Select the first exchange where the trading strategy will be executed.",
    "Select the second exchange where the trading strategy will be executed.": "Select the second exchange where the trading strategy will be executed.",
    "Choose the trading pair for the strategy.": "Choose the trading pair for the strategy.",
    "Enter the amount to be traded.": "Enter the amount to be traded.",
    "Specify the profit percentage you aim to achieve.": "Specify the profit percentage you aim to achieve.",
    "Click here to save the new trading strategy.": "Click here to save the new trading strategy.",
    "Click here to cancel and discard the strategy creation.": "Click here to cancel and discard the strategy creation.",
    "Here, you can see the strategies you have set up. Each strategy includes the exchanges, trading pair, minimum profit, and required balance.": "Here, you can see the strategies you have set up. Each strategy includes the exchanges, trading pair, minimum profit, and required balance.",
    "This column shows the required balance for each strategy.": "This column shows the required balance for each strategy.",
    "Edit an existing strategy.": "Edit an existing strategy.",
    "Delete a strategy you no longer need.": "Delete a strategy you no longer need.",
    "Markets section provides comprehensive tools and data to help you trade and analyze cryptocurrencies effectively.": "Markets section provides comprehensive tools and data to help you trade and analyze cryptocurrencies effectively.",
    "This section lists the top 100 cryptocurrencies by market capitalization.": "This section lists the top 100 cryptocurrencies by market capitalization.",
    "This section provides a visual representation of the market performance of various cryptocurrencies.": "This section provides a visual representation of the market performance of various cryptocurrencies.",
    "Name and symbol of the cryptocurrency.": "Name and symbol of the cryptocurrency.",
    "Current price of the cryptocurrency in USD.": "Current price of the cryptocurrency in USD.",
    "Price change percentage over the last hour.": "Price change percentage over the last hour.",
    "Price change percentage over the last 24 hours.": "Price change percentage over the last 24 hours.",
    "Trading volume of the cryptocurrency over the last 24 hours.": "Trading volume of the cryptocurrency over the last 24 hours.",
    "Market capitalization of the cryptocurrency.": "Market capitalization of the cryptocurrency.",
    "News section provides the latest updates and articles on various topics related to cryptocurrency. Stay informed about market trends, technological advancements, regulatory changes, and expert analyses to make well-informed decisions in your crypto journey.": "News section provides the latest updates and articles on various topics related to cryptocurrency. Stay informed about market trends, technological advancements, regulatory changes, and expert analyses to make well-informed decisions in your crypto journey.",
    "This section allows you to manage users, messages, and videos in your platform. Here, you can view, edit, and delete user information, handle messages, and manage video content.": "This section allows you to manage users, messages, and videos in your platform. Here, you can view, edit, and delete user information, handle messages, and manage video content.",
    "Click here to view and manage the list of users on your platform.": "Click here to view and manage the list of users on your platform.",
    "Click here to view and manage messages. You can also add new messages here.": "Click here to view and manage messages. You can also add new messages here.",
    "Click here to view and manage video content. You can also add new videos here.": "Click here to view and manage video content. You can also add new videos here.",
    "Click here to refresh the data in this section.": "Click here to refresh the data in this section.",
    "Click here to edit a user's information.": "Click here to edit a user's information.",
    "Click here to delete user.": "Click here to delete user.",
    "Profile section allows you to view and edit your personal information, change your password, and manage your account settings.": "Profile section allows you to view and edit your personal information, change your password, and manage your account settings.",
    "You can edit your personal information and change your password here.": "You can edit your personal information and change your password here.",
    "In the Settings tab, you can enable or disable two-factor authentication (2FA) and manage your tour settings.": "In the Settings tab, you can enable or disable two-factor authentication (2FA) and manage your tour settings.",
    "Manage your exchanges here. You can enable and disable your preferred exchanges.": "Manage your exchanges here. You can enable and disable your preferred exchanges.",
    "Choose a plan that suits you best. You can opt for monthly or yearly plans.": "Choose a plan that suits you best. You can opt for monthly or yearly plans.",
    "View all your earnings from referrals in this section.": "View all your earnings from referrals in this section.",
    "Click here to log out of your session.": "Click here to log out of your session.",
    "Welcome to CTrading!": "Welcome to CTrading!",
    "Before you can interact on our platform, you need to activate an exchange. Click the Activate button to proceed.": "Before you can interact on our platform, you need to activate an exchange. Click the Activate button to proceed.",
    "Please select your preferred exchange and then click the API Keys button to proceed.": "Please select your preferred exchange and then click the API Keys button to proceed.",
    "Please fill out all fields and then click the Save API Keys button.": "Please fill out all fields and then click the Save API Keys button.",

    //Bots
    "Off": "Off",
    "On": "On",
    "You need the premium version to use this feature!": "You need the premium version to use this feature!",
    "Upgrade to premium": "Upgrade to premium",
    "To use the Bot, ensure that at": "To use the Bot, ensure that at",
    "least one exchange is active": "least one exchange is active",
    "The bot will automatically operate across all active exchanges, mirroring trading strategies and executing trades on the selected pairs. Please note that the minimum amount required to activate the bot is": "The bot will automatically operate across all active exchanges, mirroring trading strategies and executing trades on the selected pairs. Please note that the minimum amount required to activate the bot is",
    "Gross Profit": "Gross Profit",
    "Sharpe Ratio": "Sharpe Ratio",
    "Sortino Ratio": "Sortino Ratio",
    "Maximum Drawdown": "Maximum Drawdown",
    "Maximum Run-Up": "Maximum Run-Up",
  },
};
